<template>
  <v-menu
    v-model="isPickerOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="localValue"
        :disabled="disabled"
        :label="label"
        persistent-hint
        :dense="dense"
        :rules="[isCalendar?required:true]"
        :prepend-icon="icons.mdiCalendar"
        v-bind="attrs"
        hide-details="auto"
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="localValue"
      no-title
      color="primary"
      @change="() => isPickerOpen = 0"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { required } from '@core/utils/validation'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Date',
    },
    disabled: Boolean,
    isCalendar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPickerOpen: false,
    icons: {
      mdiCalendar,
    },
  }),
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    required,
  },
}
</script>

<style>

</style>
