<template>
  <v-card
    height="100%"
  >
    <v-card-text>
      <v-layout
        d-flex
        align-center
        justify-space-between
      >
        <v-text-field
          v-model="tableOptions.search"
          label="Search"
          :prepend-inner-icon="icons.mdiMagnify"
          clearable
          hide-details
          solo-inverted
          rounded
          class="mr-5 search"
        ></v-text-field>
        <div class="d-flex align-center">
          <div
            class="mr-8"
          >
            <date-picker
              v-model="tableOptions.date_from"
              dense
              label="From"
            />
          </div>
          <date-picker
            v-model="tableOptions.date_to"
            dense
            label="To"
          />
        </div>
      </v-layout>
      <v-data-table
        hide-default-footer
        :items="users"
        :headers="accountHeaders"
        class="mt-7"
        :loading="loading"
        show-expand
        item-key="username"
        :search="tableOptions.search"
        :server-items-length="total"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <template v-slot:item.username="{value}">
          <a
            target="_blank"
            :href="`https://twitter.com/${value}`"
          >{{ value }}</a>
        </template>
        <template v-slot:item.total_subscriptions="{item}">
          <div class="d-flex">
            <span
              class="
            d-flex
            align-center"
            ><v-icon
              class="mr-1"
              small
              color="success"
            >{{ icons.mdiArrowUp }}</v-icon>{{ item.subscriptions?item.subscriptions.length:0 }}</span>
            <span
              class="
            d-flex
            align-center
            ml-3"
            ><v-icon
              class="mr-1"
              small
              color="error"
            >{{ icons.mdiArrowDown }}</v-icon>{{ item.unsubscriptions? item.unsubscriptions.length:0 }}</span>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers,item }">
          <td
            class="pa-4"
            colspan="12"
          >
            <v-expansion-panels accordion>
              <v-expansion-panel active-class="no-shadow">
                <v-expansion-panel-header>
                  <span class="expansion-title">Subscriptions</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-if="item.subscriptions&&item.subscriptions.length===0"
                  >
                    No data
                  </div>
                  <div
                    v-if="item.subscriptions&&item.subscriptions.length>0"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="el in item.subscriptions"
                      :key="el"
                      class="ma-2"
                    >
                      <a
                        target="_blank"
                        :href="`https://twitter.com/${el}`"
                      >{{ el }}</a>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>Unsubsctiptions</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-if="item.unsubscriptions && item.unsubscriptions.length===0"
                  >
                    No data
                  </div>
                  <div
                    v-if="item.unsubscriptions && item.unsubscriptions.length>0"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="el in item.unsubscriptions"
                      :key="el"
                      class="ma-2"
                    >
                      <a
                        target="_blank"
                        :href="`https://twitter.com/${el}`"
                      >{{ el }}</a>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          >
          </TableFooter>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiFilter, mdiArrowUp, mdiArrowDown, mdiMagnify,
} from '@mdi/js'
import TwitterApi from '@core/api/twitter'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import TableFooter from '@/components/TableFooter.vue'
import DatePicker from '@/components/DatePicker.vue'

function dateFormat(date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}

export default {
  components: { DatePicker, TableFooter },
  data() {
    const prevDate = new Date()
    prevDate.setMonth(prevDate.getMonth() - 1)

    return {
      tableOptions: {
        date_to: dateFormat(new Date()),
        date_from: dateFormat(prevDate),
        search: '',
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.itemsPerPage ? +this.$route.query.itemsPerPage : localStorage.getItem('itemsPerPage') || 50,
      },
      pages: [50, 100, 200],
      total: 50,
      isSubMode: true,
      icons: {
        mdiFilter, mdiArrowUp, mdiArrowDown, mdiMagnify,
      },
      loading: true,
      users: [],
      accountHeaders: [
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Subscriptions',
          value: 'total_subscriptions',
          sortable: true,
          filterable: true,
        },
      ],
    }
  },

  computed: {
    list() {
      return (this.isSubMode ? this.data?.subscribed : this.data?.unsubscribed) || []
    },
    totalSubscriptions() {
      return this.data?.subscribed?.length
    },
    totalUnsubscriptions() {
      return this.data?.unsubscribed?.length
    },
  },

  watch: {
    tableOptions: {
      handler(value) {
        if (this.$route.query.date_to !== this.tableOptions.date_to || this.$route.query.date_from !== this.tableOptions.date_from) {
          this.$router.replace({ query: { date_to: value.date_to, date_from: value.date_from } }).catch(() => {})
        }
        if (value.search && value.search.length > 0) {
          if (this.$route.query.search !== value.search) this.$router.replace({ query: { ...this.$route.query, search: value.search } }).catch(() => {})
        } else {
          this.$router.replace({ query: { ...omit(this.$route.query, ['search']) } }).catch(() => {})
        }
        const query = pick(value, ['page', 'search', 'date_from', 'date_to'])
        query.limit = value.itemsPerPage
        if (value.sortBy?.length) {
          query.ordering = value.sortDesc === 'desc' ? 'unsub' : 'sub'
        }

        this.getSubscribers(query)
      },
      deep: true,
    },
  },

  mounted() {
    if (this.$route.query.search) {
      this.tableOptions.search = this.$route.query.search
    }
    if (this.$route.query.date_to) {
      this.tableOptions.date_to = this.$route.query.date_to
      this.tableOptions.date_from = this.$route.query.date_from
    } else {
      this.$router.replace({ query: { date_to: this.tableOptions.date_to, date_from: this.tableOptions.date_from } }).catch(() => {})
    }
    const query = pick(this.tableOptions, ['page', 'search', 'date_from', 'date_to'])
    query.limit = this.tableOptions.itemsPerPage

    this.getSubscribers(query)
  },
  methods: {
    async getSubscribers(query) {
      try {
        this.loading = true
        const { data } = await TwitterApi.TrackableUsers.getTwitterUser(query)
        this.users = data.items
        this.total = data.total
      } catch (err) {
        this.$message.error(err)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .badge {
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  height: 20px;
  letter-spacing: 0;
  min-width: 20px;
  padding: 4px 6px;
  pointer-events: auto;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: .3s cubic-bezier(.25,.8,.5,1);
  white-space: nowrap;
}
.v-expansion-panel:before,
.v-application.theme--dark .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}
.expansion-title {
  font-size: 0.875rem;
}
.search{
  max-width: 400px;
    min-width: 40%;
}
</style>
