import axios from '@axios'

export default {
  Token: {
    getToken: () => axios.get('/twitter/token'),
    setToken: payload => axios.post('/twitter/token', payload),
    deleteToken: () => axios.delete('/twitter/token'),
  },
  TrackableUsers: {
    getUsers: params => axios.get('/twitter/track', { params }),
    addUser: users => axios.post('/twitter/track', users),
    updateUser: user => axios.patch('/twitter/track', user),
    deleteUsers: usersId => axios.post('/twitter/track/delete', usersId),
    getSubscriptions: params => axios.get('/twitter/trackable/stats', { params }),
    getTwitterUser: params => axios.get('/twitter/users/stats', { params }),
    getCurrentSubscriptions: id => axios.get(`/twitter/trackable/${id}/current-subscriptions`),
  },
}
